"use client"

import { Typography } from "@/components/ui/data-display/Typography"
import { useTheme } from "@mui/material/styles"

import { Button } from "@/components/ui/inputs/Button"
import { Container } from "@/components/ui/layout/Container"
import { useTranslation } from "@/i18n"
import { mdiRefresh } from "@mdi/js"
import Icon from "@mdi/react"
import { Stack } from "@mui/material"
import type { HTMLAttributes } from "react"
import LazyImage from "../LazyImage"
import de from "./messages/de.json"
import notFoundImage from "@/public/images/NotFound.svg"
import notFoundThumb from "@/public/images/NotFoundThumb.png"

export interface NotFoundErrorProps
  extends Partial<HTMLAttributes<HTMLDivElement>> {
  disableTitle?: boolean
  disableMessage?: boolean
  customTitle?: string
  customMessage?: string
  height?: number
  width?: number
  p?: number
  retry?: () => void
}

export default function NotFoundError({
  disableTitle,
  disableMessage,
  customTitle,
  customMessage,
  height,
  width,
  retry,
  p = 4,
  ...attributes
}: NotFoundErrorProps) {
  const theme = useTheme()
  const { t } = useTranslation(de)

  return (
    <Container {...attributes}>
      <Stack p={p} alignItems="center" spacing={1}>
        {!disableTitle && (
          <Typography variant="h6" textAlign="center">
            {!customTitle && t("title.label")}
            {customTitle && customTitle}
          </Typography>
        )}
        {!disableMessage && (
          <Typography
            color={theme.palette.text.disabled}
            variant="body1"
            textAlign="center"
          >
            {!customMessage && t("message.label")}
            {customMessage && customMessage}
          </Typography>
        )}
        <LazyImage
          width={width ?? 250}
          height={height ?? 200}
          src={notFoundImage}
          thumbnail={notFoundThumb}
          alt={t("image.alt")}
        />
        <Stack direction="row" spacing={1}>
          {retry && (
            <Button
              startIcon={<Icon path={mdiRefresh} size={1} />}
              variant="text"
              color="inherit"
              onClick={
                // Attempt to recover by trying to re-render the segment
                () => retry()
              }
            >
              {t("action.retry.label")}
            </Button>
          )}
        </Stack>
      </Stack>
    </Container>
  )
}
