import type { PopperProps } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import {
  type MouseEvent,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react"
import { Box } from "../../layout/Box"

import type { ArrayElement } from "@/global"
import styles from "./styles.module.css"

function Arrow() {
  const theme = useTheme()

  return (
    <Box
      className={styles.arrow}
      data-popper-arrow
      sx={{
        color: theme.palette.background.paper,
      }}
    />
  )
}

const modifiers: {
  [name: string]: ArrayElement<NonNullable<PopperProps["modifiers"]>>
} = {
  arrow: {
    name: "arrow",
    enabled: true,
    options: {
      element: "[data-popper-arrow]",
      padding: 8,
    },
  },
}

export const dispatchOverlayOpen = (timestamp: number) => {
  const customEvent = new CustomEvent("overlayOpen", { detail: timestamp })
  window.dispatchEvent(customEvent)
}

export default function usePopper(onClose?: () => void) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const closePopper = useCallback(() => {
    setAnchorEl(null)
  }, [])
  const initTime = useRef(Date.now())
  const openPopper = (
    event: MouseEvent<HTMLElement> | { currentTarget: HTMLElement | null },
  ) => {
    dispatchOverlayOpen(initTime.current)
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    const handler = (event: Event) => {
      // @ts-expect-error
      if (event?.detail !== initTime.current) {
        onClose?.()
        closePopper()
      }
    }
    window.addEventListener("overlayOpen", handler)
    return () => {
      window.removeEventListener("overlayOpen", handler)
    }
  }, [closePopper, onClose])

  return {
    Arrow,
    modifiers,
    popperProps: {
      anchorEl,
      open,
      transition: true,
    } as Pick<PopperProps, "anchorEl" | "open" | "transition">,
    closePopper,
    openPopper,
  }
}
