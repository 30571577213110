"use client"

/**
 * TODO:
 * this will probably be replaced once https://github.com/emotion-js/emotion/issues/2928 is resolved
 */
import { CacheProvider } from "@emotion/react"
import createCache from "@emotion/cache"
import { useServerInsertedHTML } from "next/navigation"
import { type ReactNode, useState } from "react"

export default function RootStyleRegistry({
  children,
}: {
  children: ReactNode
}) {
  const [cache] = useState(() => {
    const cache = createCache({ key: "css", prepend: true })
    cache.compat = true
    return cache
  })

  useServerInsertedHTML(() => {
    return (
      <style
        data-emotion={`${cache.key} ${Object.keys(cache.inserted).join(" ")}`}
        // biome-ignore lint/security/noDangerouslySetInnerHtml: safe to use because the inserted styles are from the cache
        dangerouslySetInnerHTML={{
          __html: Object.values(cache.inserted).join(" "),
        }}
      />
    )
  })

  return <CacheProvider value={cache}>{children}</CacheProvider>
}
