"use client"

import type { MenuProps } from "@mui/material"
import { useCallback, useMemo, useState, type MouseEvent } from "react"

export default function useMenu({
  getAnchorEl,
}: {
  getAnchorEl?: (
    event: MouseEvent<HTMLElement>,
  ) => HTMLElement | ParentNode | null
} = {}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | ParentNode>(
    null,
  )
  const open = Boolean(anchorEl)

  const closeMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const openMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const anchorEl = getAnchorEl?.(event) ?? event.currentTarget
      setAnchorEl(anchorEl)
    },
    [getAnchorEl],
  )

  const menuProps: MenuProps = useMemo(
    () =>
      ({
        anchorEl,
        open,
        onClose: closeMenu,
      }) as Pick<MenuProps, "anchorEl" | "open" | "onClose">,
    [anchorEl, open, closeMenu],
  )

  return {
    menuProps,
    closeMenu,
    openMenu,
  }
}
