"use client"

import type { FC } from "react"
import {
  ListSubheader as MuiListSubheader,
  type ListSubheaderProps,
} from "@mui/material"

export const ListSubheader: FC<ListSubheaderProps> = (props) => (
  <MuiListSubheader {...props} />
)
