import { type Primitive, z } from "zod"

function mod97(string: string) {
  const initialString = string.slice(0, 2)
  let checksum: number | undefined = undefined
  let fragment = ""

  for (let offset = 2; offset < string.length; offset += 7) {
    fragment =
      String(checksum ?? initialString) + string.substring(offset, offset + 7)
    checksum = Number.parseInt(fragment, 10) % 97
  }
  return checksum
}

// see reference post: https://stackoverflow.com/questions/21928083/iban-validation-check
// biome-ignore format: the object should not be formatted
const IBAN_CODE_LENGTHS = {
    AD: 24, AE: 23, AL: 28, AT: 20, AZ: 28, 
    BA: 20, BE: 16, BG: 22, BH: 22, BR: 29, BY: 28,
    CH: 21, CR: 22, CY: 28, CZ: 24, 
    DE: 22, DK: 18, DO: 28, 
    EE: 20, EG: 29, ES: 24,
    FI: 18, FO: 18, FR: 27, 
    GB: 22, GE: 22, GI: 23, GL: 18, GR: 27, GT: 28, 
    HR: 21, HU: 28,
    IE: 22, IL: 23, IS: 26, IT: 27, IQ: 23, 
    JO: 30, 
    KW: 30, KZ: 20, 
    LB: 28, LI: 21, LT: 20, LU: 20, LV: 21, LC: 32, 
    MC: 27, MD: 24, ME: 22, MK: 19, MR: 27, MT: 31, MU: 30, 
    NL: 18, NO: 15, 
    PK: 24, PL: 28, PS: 29, PT: 25, 
    QA: 29,
    RO: 24, RS: 22, 
    SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, SC: 31, ST: 25, SV: 28,
    TN: 24, TR: 26, TL: 23,
    UA: 29,
    VA: 22, VG: 24,
    XK: 20
  }

export const ibanValidator = z.string().refine((value) => {
  const iban = value.toUpperCase().replace(/[^A-Z0-9]/g, "") // keep only alphanumeric characters
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) // match and capture (1) the country code, (2) the check digits, and (3) the rest

  if (!code) {
    return false
  }

  // check syntax and length
  const codeLength =
    IBAN_CODE_LENGTHS[code[1] as keyof typeof IBAN_CODE_LENGTHS]
  if (!codeLength || iban.length !== codeLength) {
    return false
  }

  // rearrange country code and check digits, and convert chars to ints
  const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => {
    return String(letter.charCodeAt(0) - 55)
  })

  return mod97(digits) === 1
}, "Invalid IBAN")

export const checkIBAN = (input: string) => {
  return ibanValidator.safeParse(input).success
}

export const BicRegex = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/g
export const checkBIC = (bic: string) =>
  z.string().regex(BicRegex).safeParse(bic).success

// Source for calculation: https://www.kba.de/DE/Themen/ZentraleRegister/ZFZR/Info_behoerden/Regelungen_ZulBescheinigungen/anlage_2_Berechnung_Pruefziffer_FIN_Modulo_11_Verfahren.pdf?__blob=publicationFile&v=1
export const checkVIN = (vin: string, checkDigit?: string | null): boolean => {
  if (checkDigit === null || checkDigit === undefined) {
    // regexp check VIN
    const vinRegex = /^[A-HJ-NPR-Z0-9]{17}$/g

    return vinRegex.test(vin)
  }

  // EBCDIC mapping for characters
  const ebcdicMap: { [key: string]: number } = {
    A: 1,
    J: 1,
    B: 2,
    K: 2,
    S: 2,
    C: 3,
    L: 3,
    T: 3,
    D: 4,
    M: 4,
    U: 4,
    E: 5,
    N: 5,
    V: 5,
    F: 6,
    O: 6,
    W: 6,
    G: 7,
    P: 7,
    X: 7,
    H: 8,
    Q: 8,
    Y: 8,
    I: 9,
    R: 9,
    Z: 9,
    Ä: 1,
    Ö: 1,
    Ü: 1,
  }
  let sum = 0
  let weight = 2

  // Iterate over the VIN characters from right to left
  for (let i = vin.length - 1; i >= 0; i--) {
    const char = vin[i].toUpperCase()
    const value = Number.isNaN(Number.parseInt(char))
      ? ebcdicMap[char] || 0
      : Number.parseInt(char)

    sum += value * weight

    // Update weight
    weight = weight < 10 ? weight + 1 : 2
  }

  // Calculate the check digit
  const calculatedCheckDigit = sum % 11
  const validCheckDigit =
    calculatedCheckDigit === 10 ? "X" : calculatedCheckDigit.toString()

  return validCheckDigit === checkDigit
}

export const checkHSN = (hsn: string): boolean => {
  const hsnRegex = /^[0-9]{4}$/g
  return hsnRegex.test(hsn)
}

export const checkTSN = (tsn: string): boolean => {
  const tsnRegex = /^[0-9A-Z]{3}[0-9]{0,6}$/g
  return tsnRegex.test(tsn)
}

export const checkLicensePlate = (licensePlate: string): boolean => {
  const licensePlateRegex = /^[0-9A-Z ]*$/g
  return licensePlateRegex.test(licensePlate)
}

export const checkUUID = (
  uuid?: unknown,
  matchNilUUIDs?: boolean,
): uuid is string => {
  if (typeof uuid !== "string") return false

  /**
   * UUID's are as specified in RFC4122
   */
  const uuidNonNilRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  const uuidNilRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

  return matchNilUUIDs ? uuidNilRegex.test(uuid) : uuidNonNilRegex.test(uuid)
}

/**
 *
 * @param a object who's values are to be compared
 * @param b b object who's values are to be compared to
 * compare if values in a are distinct from their counterpart in b
 */
export const hasDistinctValue = <T>(
  a: Partial<Record<keyof T, Primitive>>,
  b: Partial<Record<keyof T, Primitive>>,
): boolean => {
  let distinct = false
  for (const key in a) {
    if (b[key] !== a[key]) {
      distinct = true
      break
    }
  }
  return distinct
}
