"use client"

import type { FC } from "react"
import {
  Dialog as MuiDialog,
  type DialogProps,
  DialogActions as MuiDialogActions,
  type DialogActionsProps,
  DialogContent as MuiDialogContent,
  type DialogContentProps,
  DialogTitle as MuiDialogTitle,
  type DialogTitleProps,
  DialogContentText as MuiDialogContentText,
  type DialogContentTextProps,
} from "@mui/material"

export const Dialog: FC<DialogProps> = (props) => <MuiDialog {...props} />
export const DialogActions: FC<DialogActionsProps> = (props) => (
  <MuiDialogActions {...props} />
)
export const DialogTitle: FC<DialogTitleProps> = (props) => (
  <MuiDialogTitle {...props} />
)
export const DialogContent: FC<DialogContentProps> = (props) => (
  <MuiDialogContent {...props} />
)

export const DialogContentText: FC<DialogContentTextProps> = (props) => (
  <MuiDialogContentText {...props} />
)
