"use client"

import { Box } from "@/components/ui/layout/Box"
import type { BoxProps } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import type { HTMLAttributes, ReactNode } from "react"

export interface StaticBadgeProps
  extends Omit<BoxProps, "color">,
    HTMLAttributes<HTMLDivElement> {
  variant?:
    | "default"
    | "primary"
    | "error"
    | "success"
    | "warning"
    | "info"
    | "empty"
  size?: "small" | "medium"
  badgeContent?: ReactNode
  sx?: BoxProps["sx"]
}

function getThemeSize(size: StaticBadgeProps["size"]) {
  switch (size) {
    case "small":
      return 1
    case "medium":
      return 2.5
    default:
      return 2.5
  }
}

export default function StaticBadge({
  variant = "default",
  badgeContent,
  size = "medium",
  sx = {},
  ...attributes
}: StaticBadgeProps) {
  const theme = useTheme()

  function getClassNamesByVariant() {
    switch (variant) {
      case "primary":
        return "bg-primary text-white"
      case "error":
        return "bg-error text-white"
      case "success":
        return "bg-success text-white"
      case "warning":
        return "bg-warning text-white"
      case "info":
        return "bg-info text-white"
      case "empty":
        return "bg-stone-50 text-stone-400 dark:bg-stone-950 dark:text-stone-600"
      default:
        return badgeContent === 0
          ? "bg-stone-50 text-stone-400 dark:bg-stone-950 dark:text-stone-600"
          : "bg-stone-200 text-stone-600 dark:bg-stone-800 dark:text-stone-400"
    }
  }

  if (badgeContent === null || badgeContent === undefined) {
    return null
  }

  return (
    <Box
      className={`${getClassNamesByVariant()} rounded-xl`}
      width={theme.spacing(getThemeSize(size))}
      height={theme.spacing(getThemeSize(size))}
      flexShrink={0}
      flexGrow={0}
      textAlign="center"
      lineHeight="1.25rem"
      sx={sx}
      {...attributes}
    >
      {badgeContent}
    </Box>
  )
}
