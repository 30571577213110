"use client"

import type { FC } from "react"
import {
  CssBaseline as MuiCssBaseline,
  type CssBaselineProps,
} from "@mui/material"

export const CssBaseline: FC<CssBaselineProps> = (props) => (
  <MuiCssBaseline {...props} />
)
