/**
 * useful for switch-case statements to ensure all cases are handled
 * https://stackoverflow.com/questions/39419170/how-do-i-check-that-a-switch-block-is-exhaustive-in-typescript
 */
export function assertUnreachable(x: never, returnValue?: unknown): never {
  if (returnValue !== undefined) {
    return returnValue as never
  }
  throw new Error(`Didn't expect to get here: ${x}`)
}
