import Icon from "@mdi/react"
import { alpha, useTheme } from "@mui/material/styles"
import type { document_category } from "dcp-types"
import type { HTMLAttributes } from "react"
import { getCategoryColor, getCategoryIcon } from "../../_utils/categoryStyles"

interface DocumentIconProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children" | "color"> {
  category?: document_category | null
  size?: number
  disabled?: boolean
  color?: string
}

export default function DocumentIcon({
  category = "other",
  size = 1,
  disabled = false,
  color: customColor,
  ...attributes
}: DocumentIconProps) {
  const theme = useTheme()
  const color = customColor ? customColor : getCategoryColor(category, theme)

  return (
    <Icon
      path={getCategoryIcon(category)}
      color={disabled ? alpha(color, 0.5) : color}
      size={size}
      {...attributes}
      className={`shrink-0 ${attributes.className} bg-transparent`}
    />
  )
}
