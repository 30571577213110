"use client"

import { useMediaQuery, type DialogProps } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useCallback, useEffect, useMemo, useState } from "react"
import { dispatchOverlayOpen } from "../../utils/Popper/usePopper"

export default function useDialog(props?: {
  open?: boolean
  disableBackdropClick?: boolean
}) {
  const theme = useTheme()
  const [open, setOpen] = useState(props?.open ?? false)
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

  const closeDialog = useCallback(() => {
    setOpen(false)
  }, [])
  const openDialog = useCallback(() => {
    dispatchOverlayOpen(Date.now())
    setOpen(true)
  }, [])

  const handleClose: DialogProps["onClose"] = useCallback(
    (e: object, reason: "backdropClick" | "escapeKeyDown") => {
      if (props?.disableBackdropClick && reason && reason === "backdropClick") {
        return
      }
      closeDialog()
    },
    [closeDialog, props?.disableBackdropClick],
  )

  const dialogProps: Partial<DialogProps> & { open: boolean } = useMemo(
    () => ({
      open,
      fullScreen,
      scroll: "paper",
      fullWidth: true,
      maxWidth: "md",
      onClose: handleClose,
    }),
    [open, fullScreen, handleClose],
  )

  useEffect(() => {
    setOpen(props?.open ?? false)
  }, [props?.open])

  return {
    dialogProps,
    closeDialog,
    openDialog,
  }
}
