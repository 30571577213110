"use client"

import { List as MuiList, type ListProps } from "@mui/material"
import { forwardRef } from "react"

export const List = forwardRef<HTMLUListElement, ListProps>((props, ref) => (
  <MuiList {...props} ref={ref} />
))

List.displayName = "List"
