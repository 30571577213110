"use client"

import {
  Paper as MuiPaper,
  type PaperProps as MuiPaperProps,
} from "@mui/material"
import { forwardRef } from "react"

export const Paper = forwardRef<HTMLDivElement, MuiPaperProps>((props, ref) => (
  <MuiPaper {...props} ref={ref} />
))
export type PaperProps = MuiPaperProps
Paper.displayName = "Paper"
