"use client"

import { Tooltip as MuiTooltip, type TooltipProps } from "@mui/material"
import { forwardRef } from "react"

export const Tooltip = forwardRef<HTMLTitleElement, TooltipProps>(
  (props, ref) => <MuiTooltip {...props} ref={ref} />,
)
Tooltip.displayName = "Tooltip"

export const TooltipMultiline = forwardRef<HTMLTitleElement, TooltipProps>(
  (props, ref) => (
    <MuiTooltip
      componentsProps={{
        popper: { sx: { whiteSpace: "pre-line" } },
      }}
      {...props}
      ref={ref}
    />
  ),
)
TooltipMultiline.displayName = "TooltipMultiline"
