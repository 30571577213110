const breakpoints = {
  zero: 0,
  xs: 320,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
  "2xl": 1920,
  "3xl": 2560,
} as const

export default breakpoints
