"use client"

import { CssBaseline } from "@/components/ui/provider/CSSBaseLine"
import {
  AdapterDayjs,
  LocalizationProvider,
} from "@/components/ui/provider/LocalizationProvider"
import { muiDarkTheme, muiLightTheme } from "@/theme/mui.config"
import { isBrowser } from "@/utils/env"
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles"
import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { deDE } from "@mui/x-data-grid/locales"
import { deDE as pickersDeDE } from "@mui/x-date-pickers/locales"
import { deDE as coreDeDE } from "@mui/material/locale"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * FIXME: get dark light mode to work
 */

const isDarkModePreference = (
  darkModePreference: MediaQueryList | null,
  // theme: 'dark' | 'light'
) => false // darkModePreference?.matches

export default function RootThemeProvider({
  children,
}: {
  children: ReactNode
  forceDarkMode?: "light" | "dark"
}) {
  // const theme = (getCookie('theme') ?? 'light') as 'dark' | 'light'
  const darkModePreference =
    isBrowser() && typeof window.matchMedia === "function"
      ? window.matchMedia("(prefers-color-scheme: dark)")
      : null

  const [mode, setMode] = useState<"light" | "dark">(
    isDarkModePreference(darkModePreference) ? "dark" : "light",
  )

  const updateColorSchema = useCallback(() => {
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (isDarkModePreference(darkModePreference)) {
      document.documentElement.classList.add("dark")
      setMode("dark")
      // setCookie('theme', 'dark')
    } else {
      document.documentElement.classList.remove("dark")
      setMode("light")
      // setCookie('theme', 'light')
    }
  }, [darkModePreference])

  useEffect(() => {
    darkModePreference?.addEventListener("change", updateColorSchema)

    // update color scheme on mount
    updateColorSchema()

    return () => {
      darkModePreference?.removeEventListener("change", updateColorSchema)
    }
  }, [darkModePreference, updateColorSchema])

  // const runInCLient = isBrowser()
  const muiTheme = useMemo(() => {
    // const rootElement = runInCLient ? document?.getElementById('root') : null
    const selectedTheme = mode === "light" ? muiLightTheme : muiDarkTheme

    return createTheme(
      {
        ...selectedTheme,
        components: {
          ...selectedTheme.components,
          MuiPopover: {
            ...(selectedTheme?.components?.MuiPopover ?? {}),
            defaultProps: {
              ...(selectedTheme?.components?.MuiPopover?.defaultProps ?? {}),
              // container: rootElement,
            },
          },
          MuiPopper: {
            ...(selectedTheme?.components?.MuiPopper ?? {}),
            defaultProps: {
              ...(selectedTheme?.components?.MuiPopper?.defaultProps ?? {}),
              // container: rootElement,
            },
          },
          MuiDialog: {
            ...(selectedTheme?.components?.MuiDialog ?? {}),
            defaultProps: {
              ...(selectedTheme?.components?.MuiDialog?.defaultProps ?? {}),
              // container: rootElement,
            },
          },
          MuiModal: {
            ...(selectedTheme?.components?.MuiModal ?? {}),
            defaultProps: {
              ...(selectedTheme?.components?.MuiModal?.defaultProps ?? {}),
              // container: rootElement,
            },
          },
        },
      },
      deDE,
      pickersDeDE,
      coreDeDE,
    )
  }, [mode])

  return (
    <>
      <CssBaseline enableColorScheme />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          {/* TODO: identify locale and define it here */}
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            {children}
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  )
}
