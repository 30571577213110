import { arrayOfAll } from "@/utils/types/arrayOfAll"
import { assertUnreachable } from "@/utils/types/helpers"
import {
  mdiAccount,
  mdiAccountCard,
  mdiAccountCash,
  mdiAccountInjury,
  mdiAccountTieHat,
  mdiBankCheck,
  mdiBankTransferIn,
  mdiBankTransferOut,
  mdiCarInfo,
  mdiCheckUnderlineCircle,
  mdiFile,
  mdiFileCertificate,
  mdiFileDocumentCheck,
  mdiFileFindOutline,
  mdiFileOutline,
  mdiFilePercent,
  mdiFileQuestionOutline,
  mdiFileSign,
  mdiFlash,
  mdiGavel,
  mdiHandFrontRight,
  mdiImage,
  mdiImageMultiple,
  mdiNoteOutline,
  mdiReceiptText,
  mdiReceiptTextArrowRight,
  mdiReceiptTextSend,
  mdiShieldCar,
  mdiTextBox,
  mdiWrenchClock,
} from "@mdi/js"
import type { ChipProps } from "@mui/material"
import type { Theme } from "@mui/material/styles"
import type { document_category } from "dcp-types"

type InvoiceCategory = Extract<document_category, "invoice_rvg">
type ReceiptCategory = Extract<
  document_category,
  | "invoice_survey"
  | "invoice_repair"
  | "invoice_rental_car"
  | "invoice_other"
  | "insurance_communication"
  | "cost_estimate"
  | "survey"
>
type ImportantCategory = Extract<
  document_category,
  | "power_of_attorney"
  | "insurance_communication"
  | "leasing_company_communication"
>

export const getInvoiceCategoryList = (): InvoiceCategory[] => {
  return arrayOfAll<InvoiceCategory>()(["invoice_rvg"])
}

export const getReceiptCategoryList = (): ReceiptCategory[] => {
  return arrayOfAll<ReceiptCategory>()([
    "invoice_survey",
    "invoice_repair",
    "invoice_rental_car",
    "invoice_other",
    "insurance_communication",
    "cost_estimate",
    "survey",
  ])
}

export const getImportantCategoryList = (): ImportantCategory[] => {
  return arrayOfAll<ImportantCategory>()([
    "power_of_attorney",
    "insurance_communication",
    "leasing_company_communication",
  ])
}

export const isImportantCategory = (
  category?: document_category | null,
): category is ImportantCategory => {
  return category
    ? getImportantCategoryList().includes(<ImportantCategory>category)
    : false
}

export const isInvoiceCategory = (
  category?: document_category | null,
): category is InvoiceCategory => {
  return category
    ? getInvoiceCategoryList().includes(<InvoiceCategory>category)
    : false
}

export const isReceiptCategory = (
  category?: document_category | null,
): category is ReceiptCategory => {
  return category
    ? getReceiptCategoryList().includes(<ReceiptCategory>category)
    : false
}

export const getCategoryIcon = (category: document_category | null): string => {
  if (!category) {
    return mdiFileQuestionOutline
  }

  switch (category) {
    case "power_of_attorney":
      return mdiCheckUnderlineCircle
    case "data_processing_agreement":
      return mdiFileSign
    case "instructions_for_withdrawal":
      return mdiFileSign
    case "leasing_company_communication":
      return mdiBankCheck
    case "vehicle_registration_certificate":
      return mdiCarInfo
    case "claimant_documents":
      return mdiAccount
    case "purchase_contract":
      return mdiFilePercent
    case "opponent_documents":
      return mdiAccountCard
    case "invoice_survey":
      return mdiReceiptText
    case "invoice_repair":
      return mdiReceiptText
    case "invoice_rental_car":
      return mdiReceiptText
    case "invoice_other":
      return mdiReceiptText
    case "invoice_rvg":
      return mdiReceiptTextArrowRight
    case "survey":
      return mdiFileCertificate
    case "order":
      return mdiFileDocumentCheck
    case "cost_estimate":
      return mdiFile
    case "repair_plan":
      return mdiWrenchClock
    case "liability_request":
      return mdiTextBox
    case "damage_quantification":
      return mdiReceiptTextSend
    case "objection":
      return mdiHandFrontRight
    case "insurance_communication":
      return mdiShieldCar
    case "accident_report":
      return mdiFlash
    case "accident_photos":
      return mdiImage
    case "personal_injury":
      return mdiAccountInjury
    case "police_report":
      return mdiAccountTieHat
    case "court_mail":
      return mdiGavel
    case "call_notes":
      return mdiNoteOutline
    case "incoming_payment_receipt":
      return mdiBankTransferIn
    case "outgoing_payment_receipt":
      return mdiBankTransferOut
    case "confirmation_of_assumption_of_repair_costs":
      return mdiAccountCash
    case "photo_documentation":
      return mdiImageMultiple
    case "other":
      return mdiFileOutline
    case "audit_report":
      return mdiFileFindOutline
  }

  assertUnreachable(category)
}

export const getCategoryColor = (
  category: document_category | null,
  theme: Theme,
) => {
  if (!category) {
    return theme.palette.action.disabled
  }

  if (isInvoiceCategory(category)) {
    return theme.palette.primary.main
  }
  if (isReceiptCategory(category)) {
    return theme.palette.secondary.main
  }
  if (isImportantCategory(category)) {
    return theme.palette.primary.dark
  }

  switch (category) {
    case "incoming_payment_receipt":
      return theme.palette.success.main
    case "outgoing_payment_receipt":
      return theme.palette.error.main
    default:
      return theme.palette.action.active
  }
}

export const getCategoryChipColor = (
  category?: document_category | null,
): ChipProps["color"] => {
  if (isInvoiceCategory(category)) {
    return "primary"
  }
  if (isReceiptCategory(category)) {
    return "default"
  }
  if (isImportantCategory(category)) {
    return "default"
  }

  switch (category) {
    case "incoming_payment_receipt":
      return "success"
    case "outgoing_payment_receipt":
      return "error"
    default:
      return "default"
  }
}
