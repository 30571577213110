"use client"

import {
  CircularProgress as MuiCircularProgress,
  type CircularProgressProps,
} from "@mui/material"
import { forwardRef } from "react"

export const CircularProgress = forwardRef<
  HTMLSpanElement,
  CircularProgressProps
>((props, ref) => <MuiCircularProgress ref={ref} {...props} />)
CircularProgress.displayName = "CircularProgress"
