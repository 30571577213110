"use client"

import {
  IconButton as MuiIconButton,
  type IconButtonProps as MuiIconButtonProps,
} from "@mui/material"
import { forwardRef } from "react"
import { CircularProgress } from "../../feedback/CircularProgress"

export const IconButton = forwardRef<
  HTMLButtonElement,
  MuiIconButtonProps & { loading?: boolean }
>(({ loading, children, ...props }, ref) => {
  return (
    <MuiIconButton {...props} ref={ref}>
      {loading ? (
        <CircularProgress thickness={4} size={20} color="inherit" />
      ) : (
        children
      )}
    </MuiIconButton>
  )
})
export type IconButtonProps = MuiIconButtonProps

IconButton.displayName = "IconButton"
