"use client"

import { Box } from "@/components/ui/layout/Box"
import type { BoxProps } from "@mui/material"
import { keyframes, styled } from "@mui/material/styles"
import Image, { type ImageProps, type StaticImageData } from "next/image"
import { isFunction, omit } from "radash"
import { useState } from "react"

interface LazyImageProps extends ImageProps {
  // image: StaticImageData
  // inkscape gives better results than ImageMagick convert on the command line
  // inkscape -w 24 -h 24 input.svg -o output.png
  thumbnail?: StaticImageData
  width?: number
  height?: number
}

const pulseAnimation = keyframes`
 0% { opacity:  1}
 50% { opacity: 0.5}
 100% { opacity: 1}
`

export const LazyImageContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    ["loaded", "width", "height", "thumbnail"].every((_) => prop !== _),
})<
  BoxProps & Omit<LazyImageProps, "image" | "alt" | "src"> & { loaded: boolean }
>(({ width, height, loaded, thumbnail }) => ({
  backgroundImage: loaded ? undefined : `url(${thumbnail?.src})`,
  filter: !loaded ? "blur(5px)" : undefined,
  width: `${width}px`,
  height: `${height}px`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  position: "relative",
  transition: "opacity 200ms ease-in-out, filter 200ms ease-in-out",
  animation: !loaded ? `${pulseAnimation} 2s infinite` : undefined,
  ["& > img"]: {
    transition: "opacity 200ms ease-in-out",
  },
}))

export default function LazyImage({
  thumbnail,
  src,
  alt,
  width,
  height,
  ...attributes
}: LazyImageProps) {
  const [loaded, setLoaded] = useState(false)
  return (
    <LazyImageContainer
      thumbnail={thumbnail}
      width={width}
      height={height}
      loaded={loaded}
    >
      <Image
        width={width}
        height={height}
        onLoad={(event) => {
          setLoaded(true)
          isFunction(attributes.onLoad) && attributes.onLoad(event)
        }}
        loading="lazy"
        className={`object-center bg-center ${
          loaded ? "opacity-100" : "opacity-0"
        }`}
        src={src}
        alt={alt ?? "Abbildung"}
        {...omit(attributes, ["onLoad"])}
      />
    </LazyImageContainer>
  )
}
