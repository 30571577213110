"use client"

import type { FC } from "react"
import {
  ListItemIcon as MuiListItemIcon,
  type ListItemIconProps,
} from "@mui/material"

export const ListItemIcon: FC<ListItemIconProps> = (props) => (
  <MuiListItemIcon {...props} />
)
